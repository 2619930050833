.container-ml{
    display: grid;
    background-color: $black;
    padding: 13rem 0rem 0rem 0rem;
    // padding-top: 13rem;
    font-family:$font-2;
    // padding-bottom: 2.5rem;
    // padding-top: 19em;
    .ml{
        color: $color-2;
        font-family: $font-1;
        text-align: center;
        padding: 2rem 0;
        font-size: 4rem;
        @media screen and (max-width: 856px){
            font-size: 3.8em;
          }
          @media screen and (max-width: 420px){
            font-size: 3.1em;
          }
    }
    .lign{
        color:$white;
        // display: block;
        text-align: center;
        align-items: center;
        font-size: 2rem;
        line-height: 10rem;
        @media screen and (max-width: 858px){
            font-size: 1.7em;
            line-height: 5rem;
          }
          @media screen and (max-width: 520px){
            font-size: 1.4em;
          }
          @media screen and (max-width: 482px){
            font-size: 1.2em;
          }
          @media screen and (max-width: 420px){
            font-size: 1em;
            line-height: 2rem;
          }
    }
    p{
        color:$white;
        line-height: 2rem;
        font-size: 1.5rem;
        padding: 0 1rem;
        @media screen and (max-width: 856px){
            font-size: 1.2em;
          }
        @media screen and (max-width: 520px){
            font-size: 1em;
          }
          @media screen and (max-width: 420px){
            font-size: 0.8em;
            line-height: 1rem;
          }
          @media screen and (max-width: 360px){
            font-size: 0.7em;
          }
    }
    b{
        color:$white;
        font-size: 1.5rem;
        line-height: 5rem;
        padding: 0 2rem;
        @media screen and (max-width: 420px){
            font-size: 1.1em;
            line-height: 3rem;
          }
    }
}