
@font-face {
    font-family: "font-1";
    src: url(./assets/fonts/GreatVibes-Regular.ttf);
  }
  @font-face {
    font-family: "font-2";
    src: url(./assets/fonts/JosefinSans-Regular.ttf);
  }
  
  $font-1: "font-1", sans-serif;
  $font-2: "font-2", serif;
  //silver
  $color-1:  #c4cace;
  //silver foncé
  $color-2: #736962;
  $color-3: rgb(113, 126, 139);
  $color-4: #fdeeee;
  $black: rgb(0, 0, 0);
  $white: rgb(253, 253, 253);
  $spacing: 1.5em;
  $border-radius: 5px 25px;
  $shadow-1: 0.6px 0.5px $white;
  $shadow:
  -0.0075em 0.0075em 0 #000000, 0.005em 0.005em 0 #0c0c0c, 0.01em 0.01em 0 #000000, 0.015em 0.015em #585454, 0.02em 0.02em 0 #948e8e, 0.025em 0.025em 0 #afafaf, 0.03em 0.03em 0 #000000, 0.035em 0.035em 0 #b9b9b9;


  
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    outline: none;
    border: none;
    text-decoration: none;
   
  
    &::body {
      box-sizing:content-box;
      background: $color-1;
      overflow-x: none !important;
    }
    .container{
      padding: 0rem 2rem 0rem 2rem;
    }
  }
  a,
  a:visited {
    color: $color-1;
    text-align: center;
  }
  i {
    transition: 0.15s;
  
    &:hover {
      transform: scale(1.1);
    }
  }
  body {
    font-family: $font-1, sans-serif;
    background-color: $black; //jordane
    .container{
      display: flexbox;
      width: 95%;
      margin:0rem auto;
      padding-top: 11rem;

      p{
        margin:1rem 0;
      
    }
}
  }
  
  .error {
    color: red;
    font-weight: bold;
  }
  .success {
    color: green;
    font-weight: bold;
  }

  //réseaux sociaux header
i{
  //color:$black;
  margin-top: 0.9rem;
  padding: 0 18px;
  font-size:2rem;

} 
i#menu{
  color:$white;
  font-size:1.5rem;
}  
//paralaxe haut 
//paralaxe
.paralaxe{
  //background: url("../img/chalants.jpg") no-repeat center;
 background: $black;
  width: 93rem;
  background: cover;
  max-width: 100%;
  height: 104rem;
  right:0rem;
  left:0rem;
  //opacity: 0.2;
  position: fixed;
   top: -6rem;
  z-index: -2;
  width: 100%;
  margin-bottom: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-position: 40% 58%;
  background-size: cover;
  background-attachment: fixed;
}



  