.main-footer{
    // position: fixed;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 0fr;
    grid-gap: 1.5rem;
    // background: url("./../../img/wave.svg") no-repeat;
    background-size: cover;
    background-color: $black;
    background-position: bottom;
    // background-color: $black;
    // background: linear-gradient(45deg, #292626 30%,#413a3a  80%);
    // background-color: linear-gradient(to bottom,rgba(0, 0, 0, 0) 10%, rgb(73, 73, 73) 10%);
    justify-items: center;
    //height: 14.5rem;
    width: 100%;
    padding: 1.5rem 3rem 1.5rem 3rem;
    color: $color-1;
    z-index: 1000;
    transition: .4s ease-out;
    text-decoration: none;
    font-family: $font-2; 
    h4{  
        color: $color-1;
        text-transform: uppercase;
        justify-items: center;
        grid-template-rows: 1fr;
        span{
            grid-template-rows: 1fr;
            padding: 0.2rem;
            justify-content: space-around;
        }
        .br{
            display: flex;
        }
    }
}
@media screen and (max-width:767px){
    .main-footer{
        grid-template-columns: 1fr;
        grid-gap: 1.5rem;
        justify-items: center;
        padding: 1rem;
        .span{
            display: none;
        }
    }
}
@media screen and (max-width:350px){
    .main-footer{
        height: 16rem; 
    }
}
