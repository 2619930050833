$button-color: #29a8e2;
$bg-color:#444;

.slide-container{
    background-color: $bg-color;
    height: 700px;
    padding-top: 4rem;
}
.slider{
    font-family: 'Times New Roman', Times, serif;
    max-width: 700px;
    border: 10px solid $button-color;
    margin:  80px auto; 
    position: relative;
    overflow: hidden;
        .left-slide, .right-slide{
            position: absolute;
            height: 40px;
            width: 40px;
            background-color: $bg-color;
            border-radius: 50%;
            color: #FFF ;
            cursor: pointer;
            font-size: 20px;
            top: 50%;
            margin-top: -20px;
            text-align: center;
            line-height: 40px;
            transition: all .5s ease;
            &:hover{
                background-color:  $button-color;
                box-shadow: 0px 0px 5px black;
            }
        }
        .left-slide{
            left: 30px;
            cursor: pointer;
        }
        .right-slide{
            right: 30px;
            cursor: pointer;
        }
}
.slider .slider-items .item .img{
    max-width: 100%;
    display: block;
    animation: zoom 1s ease;
}
@keyframes zoom{
    0%{transform:scale(2);opacity: 0;}
    50%{transform:scale(2);}
    100%{transform:scale(1);opacity: 1;}
}
.slider .slider-items .item{
    display: none;
    position: relative;
}
.slider .slider-items .item .caption{
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0px;
    left: 0px;
    background-color:rgba(0,0,0,.5);
    line-height: 60px;
    text-align: center;
    color: #FFF;
    font-size: 30px;
}
.slider .slider-items .item.active{
    display: block;
}