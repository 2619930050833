.bg-huitre{
    display: flex;
    background-color: $black;
    // background: url("../img/huitres-sel.jpg") no-repeat center;
    background: cover;
    max-width: 100%;
    height: 60rem;
    right: 0rem;
    left: 0rem;
    position: fixed;
    top: 0;
    z-index: -2;
    width: 100%;
    margin-bottom: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    
  }
  .title-content{
    padding-top: 16rem;
    margin-bottom: 2rem;
    color: $white;
    letter-spacing: 1.9px;
    text-align: center;
    font-size :5rem;
    position: relative;
    text-align: center;
    @media screen and (max-width: 520px){
      font-size :3.5rem;
  }
}
  .huitre-sel{
    display: flex;
    position: fixed;
    width: 40%;
    z-index: -2;
    @media screen and (max-width: 600px){
      opacity: 0.6;
    }
  }

//title video settings
  .title-video{
    //background:url("../img/huitres-sel.jpg")no-repeat;
    background-size: 100%;
    padding:2rem 0rem 0rem 0rem;
    margin-bottom: 2rem;
    //background-attachment: fixed;
    //@at-root: right;
    //opacity: 1;
    color: $white;
    font-size: 5rem;
    text-align: center;
    @media screen and (max-width: 550px)
       {
        font-size: 4.5rem;
       }
       @media screen and (max-width: 520px){
        font-size :3.5rem;
    }
}
  //video settings

.video-container {
    display: flex;
    position: relative;
    padding-bottom: 4%;
    height:100%;
    margin-bottom: 0px;
}

.video-container iframe, .video-container object, .video-container embed {
  margin: 0 auto;
  margin-top: 3rem;
  padding: 0;
  border: 0;
  width: 1000px;
  height: 500px;
  vertical-align: baseline;
  @media screen and (max-width: 685px){
    width: 600px;
    height: 350px;
  }
  @media screen and (max-width: 520px){
    width: 500px;
    height: 250px;
  }
}

