.container-home{
    background-color: $black;}
//     .text-home{
//         h1{
//             font-size: -webkit-xxx-large;
//             z-index: +100;
//             padding: 2rem 0 3rem 0;
//             text-shadow: $shadow;
//         &::before, &::after{
//             content: '';
//             position: absolute;
//             top:0;
//             height: 100%;
//             width: 50%;
//         }
//         &::before{
//             left: 0;
//             border-bottom: 6px solid $white;
//         }
//         &::after{
//             right:0;
//             border-top: 6px solid $white;
//         }
//     }
  
//     }
// }
//video
// .videoHome{
//     margin-bottom: -6px;
//     width: 100%;
//     z-index: -100;
// }
.container-video{
    //position:absolute;
    width: 100%;
    height: 65rem;
    overflow: hidden;
    //display: flex;
    align-items: center;
    margin-bottom: -40px;
    @media screen and (max-width: 1693px){
        margin-bottom: -41px;
    }
    @media screen and (max-width: 1660px){
        height:45rem;
        margin-bottom: -30px;
        // margin-top: 8.5rem;
    }
    @media screen and (max-width: 1602px){
        height:50rem;
        margin-bottom: -30px;
    }  
    @media screen and (max-width: 1351px){
    height:50rem;
    //  margin-top: 6.5rem;
    // margin-bottom: -21px;
}  
  
    @media screen and (max-width: 1026px){
        height: 90%;
        margin-bottom: -9px;
}  
    @media screen and (max-width: 990px){
        // height: 40%;
        // margin-top: 9rem;
        margin-bottom: -6px;
}   
@media screen and (max-width: 854px){
    margin-bottom: -7px;
}  
}
.container-video .videoHome{

    top: 0rem;
    left:0;
    width: 100%;
    height: 95%;
    object-fit: cover;
    margin-top: 3.7rem;
    padding-bottom: 3rem;
    //margin-top: 22.5rem;
    @media screen and (max-width: 1025px){
        padding-bottom: 0rem;
    }  
}
@media screen and (max-width: 1025px){
    .videoHome{
        padding-top: 3.5rem;
    }
} 
////test
.page{
    margin-top: 0;
    // width: 100%;
    // align-items: center;
    // display: flex;
    // justify-content: center;
}
.welcome{
    top: 23rem;
    display: inline-block;
    position: relative;
    left: 0;
    font-size: $font-2;
    //margin-left: 15vw;
    //background:$white;
    padding: 1rem;
    font-size: 5rem;
    text-align: center;
    text-shadow: $shadow;
    mix-blend-mode: screen;
    /////test
    span{
        overflow: hidden;
        display: block;
        padding-left: 0.4em;
        padding-right: 0.4rem;
        span{
            display: block;
            animation: titleReveal .7s both;
        }
    }
    @media screen and (max-width: 990px){
        margin-left: 0vw;
        font-size: 7vw;
}  
    @media screen and (max-width: 459px){
        top: 16rem;
}  
}
@keyframes titleReveal{
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}






///////Sections settings
// .title-page{
//     color: #736962;
//     /* text-align: center; */
//     font-size: 8rem;
//     /* position: relative; */
//     display: block;
//     position: sticky;
//     margin-top: 0rem;
//     margin-bottom: 0rem;
//     color: $color-2;
//     text-align: center;
//     //font-size :5rem;
//     position: relative;
//     text-align: center;
//     letter-spacing: 3px;
//     @media screen and (max-width: 520px){
//       font-size :3.5rem;
//   }
// }

.sections{
    overflow: hidden;
}
//////Section 1
.paralaxe-section-1{
    background: url("../img/gratons.jpg") no-repeat center;
    background-size: cover;
    background-attachment: fixed; 
}
.section-1{
            z-index: -10;
            align-items: center;
            padding: 3rem;
            width: 100%;
            height: 15rem;   
            background-color: $black; 
            cursor: pointer;
            @media screen and (max-width: 600px){
                height: 12rem;
        } 
        @media screen and (max-width: 375px){
            padding: 1rem;
            height: 8rem;
    }  
    @media screen and (max-width: 360px){
        height: 7rem;
}  
    @media screen and (max-width: 350px){
        height: 6rem;
} 
            .title-section-1{
                color: $color-1;
                width: 100%;
                font-size: 3.5rem;
                padding-top: 2.5rem;
                opacity: 1;      
                letter-spacing: 2px;
                line-height: 1em;
                @media screen and (max-width: 1123px){
                    padding-top: 1.4rem;
            } 
            @media screen and (max-width: 660px){
                    font-size: 40px;
            }  
            @media screen and (max-width: 600px){
                padding-top: 0rem;
                font-size: 30px;
        }  
        @media screen and (max-width: 375px){
            padding-top: 1rem;
    }  
            @media screen and (max-width: 360px){
                padding: 1rem;
                font-size: 20px;
                padding-top: none;
        }  
        @media screen and (max-width: 320px){ 
            font-size: 17px;   
    }  
            }
}
.container-section-1{
        display: flex;
        direction: rtl;
        .btn-1{
            display: block;
            width: 50%;
            opacity: 0.8;
    } 
}
/////////section 2
.paralaxe-section-2{
    background: url("../img/photo2.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed; 
}
.section-2{
            z-index: -10;
            padding: 3rem;
            align-items: center;
            width:100%;
            height: 15rem;   
            background-color: $black;cursor: pointer;
            @media screen and (max-width: 600px){
                height: 10rem;
        } 
        @media screen and (max-width: 383px){
            height: 8rem;
            padding: 2rem;
    }  
    @media screen and (max-width: 375px){
        padding: 1rem;
        height: 6rem;
}  
@media screen and (max-width: 360px){
    height: 7rem;
}  
@media screen and (max-width: 355px){
    height: 6.5rem;
}
@media screen and (max-width: 350px){
    height: 6rem;
} 
            .section-2:hover{
                background-color: $white;
            }
            .title-section-2{
                color: $color-1;
                width: 100%;
                font-size: 3.5rem;
                padding-top: 2.5rem; 
                opacity: 1; 
                line-height: 1em;
                @media screen and (max-width:980px){
                    padding-top: 1.5rem;
            } 
                @media screen and (max-width: 892px){
                    padding-top: 1.5rem;
            } 
            @media screen and (max-width: 634px){
                padding-top: 0rem;
        }     
                @media screen and (max-width: 630px){
                    font-size: 2.5rem;
                    padding-top: 1.5rem;
            }      
                @media screen and (max-width: 600px){
                    font-size: 2rem;
                    padding-top: 0.5rem;
            }  
            @media screen and (max-width: 448px){
                font-size: 1.8rem;
                padding-top: 0.5rem;
        }  
        @media screen and (max-width: 417px){
            padding-top: 0rem;
    }  
            @media screen and (max-width: 360px){
                padding: 0.5rem;
                font-size: 25px;
                padding-top: none;
        }  
        @media screen and (max-width: 320px){ 
            font-size: 17px;   
    }  
            
              
            }
}
.container-section-2{
        display: flex;
        .btn-2{
            display: block;
            width: 50%;
            opacity: 0.8;
    } 
}
//////////section 3
.paralaxe-section-3{
    background: url("../img/cabane-1.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed; 
}
.section-3{
            z-index: -10;
            align-items: center;
            padding: 3rem;
            width: 100%;
            height: 15rem;   
            background-color: $black;
            @media screen and (max-width: 600px){
                height: 10rem;
        } 
        @media screen and (max-width: 375px){
            padding: 1rem;
            height: 6rem;
    }  
    @media screen and (max-width: 360px){
        height: 6rem;
    }  
    @media screen and (max-width: 320px){
           margin-bottom: -1px;
    }  
            .title-section-3{
                color: $color-1;
                width: 100%;
                 font-size: 3.5rem;
                 padding-top: 2.5rem;
                 opacity: 1;  
                 line-height: 1em;            
            @media screen and (max-width: 892px){
                padding-top: 1.5rem;
        } 
            @media screen and (max-width: 630px){
                font-size: 2.5rem;
                padding-top: 1.5rem;
        }      
            @media screen and (max-width: 600px){
                font-size: 2rem;
                padding-top: 0.5rem;
        }  
       
        @media screen and (max-width: 360px){
            padding: 1rem;
            font-size: 25px;
            padding-top: 0.5rem;
    }  
    @media screen and (max-width: 320px){ 
        font-size: 20px; 
        padding-top: 1rem;  
}  
              
            }
}
.container-section-3{
        display: flex;
        direction: rtl;
        .btn-3{
            display: block;
            width: 50%;
            opacity: 0.8;
    } 
}
//buttom settings 
.btn {
    color: #fff;
    cursor: pointer;
    // display: block;
    font-size:0px;
    line-height: 45px;
    margin: 0 0 2em;
    position: relative;
    text-decoration: none;
    width: 100%; 
    background-color: $black;
  
}
    
    .btn:after,
    .btn:before {
      backface-visibility: hidden;
      border: 1px solid rgba($white, 0);
      bottom: 0px;
      content: " ";
      display: block;
      margin: 0 auto;
      position: relative;
      transition: all 280ms ease-in-out;
      width: 0;
    }
    
    .btn:hover:after,
    .btn:hover:before {
      backface-visibility: hidden;
      border-color: $white;
      transition: width 350ms ease-in-out;
      
      width: 100%;
    }
    
    .btn:hover:before {
     
      bottom: auto;
      top: 0;
      width: 100%;
      
    }