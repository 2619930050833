//navbar sticky
.main-header.sticky{
    //background:none;
    height: 0rem;
    padding: 3rem 0rem 3rem 0rem;
    border-bottom: 0.5px solid;
    margin-top: 0rem;
    h3{
      //color:$black;
      color: silver;
      //text-shadow:0.5px 0.5px $white;
      text-shadow: 0.1px ​0.5px 0.1px $white;
    }
    .logo{
        .logo-black{
            display: block;
            //blanc logo  width: 4rem;
             width: 7rem;
            // color: $black;
            // background-color : hsla(0,0%,100%,0.9);
            // border-radius: 39px;
        }
            .logo-white{
                display: none !important;
                //display: block;
                //blanc logo
               // width: 5rem;
               //argent logo
               width: 5rem;
                color: $black;
               // background-color : hsla(0,0%,100%,0.9);
                border-radius: 39px;
               // box-shadow: 0px -4px 5px #aaa; 
                margin-bottom: 1rem;
                @media screen and (max-width:600px){
                    margin-bottom: 0.5rem;
                    }
                @media screen and (max-width:398px){
                    //logoblanc
                    //width: 4.5rem;
                    width: 4rem;
                    }
            }     
    }
    #menu{
        //color:$black;
        color: silver;
       // text-shadow: 0.5px 0.5px white;
        text-shadow:  0.1px ​0.5px 0.1px $white;
        @media screen and (max-width:767px){
         color:$white;
        }
    }
    .menu-icon{
    &__line{
        background: $white;
        &::before, &::after{
            background: $white;
        }
        }
    }
}
//Navbar normal
.main-header{
    // display: none;
    font-family: $font-2;
    margin-top: 0rem;
    position: fixed;
    display: flex;
    // background-color:$black;
    background: $black;
    justify-content: space-around;
    align-items: center;
    //a voir
    //height: 12.5rem;
    height: 11rem;
    width: 100%;
    color: silver;
    z-index: 1;
    transition: .7s ease-out;
    .logo{
        .logo-white{
            //logo blanc
            //width: 12rem;
            //argent logo
            width: 15rem;
            //margin-top: 0.5rem;
        }
        .logo-black{
            display: none;
        }
    }
    h3{
        color: silver;
        text-transform: uppercase;
    }

    .nav-links{
        display: flex;
        list-style:none;

        .nav-link{
            transition:
            opacity .4s ease-in-out,
            transform .6s cubic-bezier(0.175,0.885,0.20,1.275);

            &:nth-of-type(2){
                transition-delay: .1s;
            }
            &:nth-of-type(3){
                transition-delay: .2s;
            }
            &:nth-of-type(4){
                transition-delay: .3s;
            }
            h3{
                margin: .2rem;
                padding:1rem 1rem 0.5rem 1rem;
            }
            h3:hover{
                border-bottom: 2px solid $white;
                
            }
        }
    }
    .menu-icon{
        position: relative;
        padding:26px 10px;
        cursor: pointer;
        z-index: 1;
        display :none;

        &__line{
            display: block;
            position: relative;
            background: $white;
            height: 2px;
            width: 20px;
            border-radius: 4px;

            &::before, &::after{
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                background: $white;
                transition: background .8s ease;
            }
            &::before{
                transform: translateY(-5px); ;
            }
            &::after{
                transform: translateY(5px);
            }
        }
    }
    .menu-btn{
        position: absolute;
        top:-100px;
        display:none;

        &:focus ~ .menu-icon{
            .menu-icon__line{
                &::before{
                    transform:translateY(-7px);   
                }
                &::after{
                    transform:translateY(7px);   
                }
            }
        }
    }
        .menu-icon{
            &__line,
            &__line::before,
            &__line::after{
                //color span
                background-color: $white;
            }
    }
}

//setting cercle
@media screen and (max-width:767px){
    .main-header{
            height: 7.5rem;
        .logo .logo-white
        {
            display: flex;
            //logoblanc
            //width: 5rem;
            //logo argent
            width: 9rem;
            margin-bottom: 1em;
        }
        .menu-icon{
            display:block;
            &__line{
                animation:closedMid 0.8s backwards;
                animation-direction: reverse;
                &::before{
                    animation:closedTop 0.8s backwards;
                    animation-direction:reverse;
                }
                &::after{
                    animation:closedBtm 0.8s backwards;
                    animation-direction:reverse;
                }
            }
        }
        .nav-links{
            position:absolute;
            margin-top: 0px;
            top:0;
            left:0;
            opacity: 0;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 10rem 0;
            width: 100%;
            height: 100vh;
            font-size: 1rem;
            //links responsif color
            color:$white;
            background-color:rgba(0, 0, 0, 0.794);
            transition: 
                opacity .8s .5s,
                clip-path 1s .5s;
            clip-path: circle(200px at top right);

            .nav-link{
                opacity: 0;
                transform: translateX(100%);
                width: 100%;
                text-align:center;

                h3{
                    display: block;
                    padding: 2rem 0;
                    color: $white;  
                }
            }
        }
        .menu-btn:checked ~ .nav-links{
            opacity: 1;
            clip-path: circle(100% at center);

            .nav-link{
                opacity: 1;
                transform: translateX(0);
                transition:
                    opacity .4s ease-in-out,
                    transform .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &:nth-of-type(1){
                transition-delay: .7s;
            }
                &:nth-of-type(2){
                    transition-delay: .8s;
                }
                &:nth-of-type(3){
                    transition-delay: .9s;
                }
                &:nth-of-type(4){
                    transition-delay: 1s;
                }
            }
        }
        .menu-btn:checked ~ .menu-icon{
            border-radius: 50%;

            .menu-icon__line{
                background: $white;
                animation: openMid 0.8s forwards;

                &::before{
                    background: $white;
                    animation: openTop 0.8s forwards;
                }
                &::after{
                    background: $white;
                    animation: openBtm 0.8s forwards;
                }
            }
        }
    }
}
@media screen and (max-width:767px){
    .logo .logo-white
    {
        padding-top: 10px;
        display: flex !important;
    }
}
@media screen and (max-width:390px){
    .main-header{
        height: 7rem;

    }
    .logo .logo-white
    {
        padding-top: 15px;
        display: flex !important;
    }
}

//animation du menu Hamburger

@keyframes openTop{
    0%{
        transform: translateY(-5px)
        rotate(0deg);
    }
    50%{
        transform: translateY(0px)
        rotate(0deg);
    }
    100%{
        transform: translateY(0px)
        rotate(90deg);
    }
}
@keyframes closedTop{
    0%{
        transform: translateY(-5px)
        rotate(0deg);
    }
    50%{
        transform: translateY(0px)
        rotate(0deg);
    }
    100%{
        transform: translateY(0px)
        rotate(90deg);
    }
}
@keyframes openMid{
    50%{
        transform: rotate(0deg)
    }
    100%{
        transform: rotate(45deg)
    }
}
@keyframes closedMid{
    50%{
        transform: rotate(0deg)
    }
    100%{
        transform: rotate(45deg)
    }
}
@keyframes openBtm{
    0%{
        transform: translateY(5px)
        rotate(0deg);
    }
    50%{
        transform: translateY(0px)
        rotate(0deg);
    }
    100%{
        transform: translateY(0px)
        rotate(90deg);
    }
}
@keyframes closedBtm{
    0%{
        transform: translateY(5px)
        rotate(0deg);
    }
    50%{
        transform: translateY(0px)
        rotate(0deg);
    }
    100%{
        transform: translateY(0px)
        rotate(90deg);
    }
}