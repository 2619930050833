.histoire{
  padding-right: 0rem;
  opacity: 1;
  z-index: 1000;
  background-color: black;
  .intro{
    display: block;
    line-height: 1.5em;
    padding: 1rem;
    color: $white;
    font-family: $font-2;
    margin-top: 1.5rem;
    font-size:1.5em;
    text-align: center;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 650px){
      font-size:21px;
    }
    .noms{
      color: rgb(151, 149, 149);
    }
  }
}

.BDR{
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(151, 149, 149);
  font-family: $font-1;
  font-size: 5rem;
  @media screen and (max-width: 578px){
    font-size:3.5rem;
  }
  @media screen and (max-width: 400px){
    font-size:2.8rem;
  }
}
.container-hist {
  background:url("../img/plant.jpg")no-repeat;
  // border-radius: 0  0 207px 207px;
  background-size: 132%; //112%;
  padding: 16rem 0rem 2rem 0rem;
  background-attachment: fixed;
  opacity: 0.8;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  @media screen and (max-width: 639px){
    background-size: 150%;
  }
  @media screen and (max-width: 599px){
    background-size: 150%;
  }
  // @media screen and (max-width: 520px){
  //   padding: 8rem 0rem 2rem 0rem;
  // }
  @media screen and (max-width: 483px){
    background-size: 200%;
  }
  @media screen and (max-width: 483px){
    background-size: 250%;
  }
h1.notre-hist {
	color: mix(rgb(255, 254, 254), #ffffff, 85%);
  // couleur gris claire title rgb(126, 116, 116)
	width: 100%;
  text-align: center;
  font-size: 6rem;
  margin-top: 6rem; 
  margin-bottom: 6rem; 
	font-weight:100;
  text-shadow: $shadow;
        @media screen and (max-width: 520px){
            font-size: 3.5rem;
            margin-top: 0em;
          }     
    }
}

////
.entries {
  background-color: $black;
    box-sizing: inherit !important;
    overflow: hidden !important;
    width:100%;
    margin:auto;
    padding-left: 1rem;
    padding: 1rem;
    left:-5px;
    .entry {
      width:calc(50% - 80px);
      float:left;
      margin-top: 50px; 
      padding:70px 20px;
      clear:both;
      text-align:right;
      @media screen and (max-width: 970px){
        display: contents;
      }
    
      &:not(:first-child) {
        margin-top:-60px;
      }
      .title {
        font-size :64px;
        margin-bottom:12px;
        position: relative;
        color:$color-2;
        text-shadow: $shadow;
        &:before {
          content: '';
          position: absolute;
          width:8px;
          height:8px;
          border:2px solid $color-2;
          background-color:$color-3;
          border-radius:100%;
          top:50%;
          transform:translateY(-50%);
          right:-73px;
          z-index:1000;
          @media screen and (max-width: 578px){
            display: none;
          }
        }
        &.big:before {
          width:24px;
          height:24px;
          transform:translate(8px,-50%);
          @media screen and (max-width: 578px){
            display: none;
          }
        }
      }
      .body {
        color:#aaa;
        @media screen and (max-width: 970px){
          padding:5rem;         
        }
        @media screen and (max-width: 578px){
          padding:0rem;  
        }
        .p {
          margin-top: -6rem;
          text-align: justify;
          color: $white;
          font-family: $font-2;
          font-size:1.5em;
          line-height:1.4em;
          @media screen and (max-width: 578px){
            margin-top: 0;
            font-size:18px;
          } 
        }
        .p2{
          margin-top: -6rem;
          margin-bottom: 4rem;
          text-align: justify;
          color: $white;
          font-family: $font-2;
          font-size:1.5em;
          line-height:1.4em;
          @media screen and (max-width: 578px){
            margin-top: 0;
            font-size:18px;
          }
          @media screen and (max-width: 970px){
           margin-bottom: 0rem;        
          }
        }
        .p3{
          margin-top: -12rem;
          margin-bottom: 4rem;
          text-align: justify;
          color: $white;
          font-family: $font-2;
          font-size:1.5em;
          line-height:1.4em;
          @media screen and (max-width: 970px){
           margin-top: -6rem;       
          }
          @media screen and (max-width: 578px){
            margin-top: 0;
            font-size:18px;
          }
          @media screen and (max-width:355px){
            margin-top: 0rem;
          }
        }
      }
      &:nth-child(2n) {
        text-align:left;
        float:right;
        .title {
          &:before {
            left:-34px;
            @media screen and (max-width: 578px){
                display: none;
              }
          }
          &.big:before {
            transform:translate(-8px,-50%);
            
          }
        }
      }
    }
  }
  .img-left{
    width: 21rem;
    margin: -10rem -33rem 0rem 0rem;
    border-radius: $border-radius;
    @media screen and (max-width: 970px){
      display: contents;
    }
}
.img-right{
  width: 21rem;
  border-radius: $border-radius;
  margin: -10rem 0rem 0rem -30rem;
  @media screen and (max-width: 970px){
    display: contents;
  }
}
//paralaxe
.paralaxe-hist{
  background: url("../img/logo-argent.png") no-repeat;
  width:10rem;
  background: cover;
  height: 74rem;
  right: 0rem;
  left: 0rem;
  opacity: 0.09;
  position: absolute;
  top: 37.5rem;
  z-index: 1;
  width: 100%;
  margin-bottom: 0;
  flex: 0 0 auto;
  background-position: 50% 50%;
  /* background-size: cover; */
  background-attachment: fixed;
  @media screen and (max-width: 1053px){
    height: 82rem;
  }
  @media screen and (max-width: 837px){
    height: 85rem;
  }
  @media screen and (max-width: 801px){
    height: 89rem;
  }
  @media screen and (max-width: 710px){
    height: 95rem;
  }
  @media screen and (max-width: 637px){
    height: 85rem;
  }
  @media screen and (max-width: 520px){
    top: 28rem;
  }
  @media screen and (max-width: 453px){
    height: 90rem;
  }
  @media screen and (max-width: 356px){
    height: 117rem;
    top: 29rem;
  }
}
