.container-form{
    padding: 6.5rem 3rem 4rem 3rem;
    z-index: 0;
    display: grid;
    grid-gap: 1.5rem;
          @media screen and (max-width: 840px){
            margin-top: -2rem;
          }
          @media screen and (max-width: 625px){
            padding: 4rem 3rem 3rem 3rem;;
          }
}
    .contact-form {
      margin: 0 0 0 0;
      display: grid;
      justify-items: center;
      @media screen and (max-width: 935px){
        width: 100%;
      }
     
     
      h2 {
        margin-bottom: 20px;
        font-size: 5rem;
        color: $white;
        //color: $color-2;
       // text-shadow: $shadow;
       @media screen and (max-width: 561px)
       {
        font-size: 3rem;
       }
       @media screen and (max-width: 404px)
       {
        font-size: 2.5rem;
       }
       @media screen and (max-width: 370px)
       {
        font-size: 2rem;
       }
      }
  
      .form-content {
        display: grid;
        grid-template-columns: 2fr;
        width: 50%;
        @media screen and (max-width:750px){
            width: 85%;
          }
         @media screen and (max-width:500px){
            width: 100%;
          }
  
        input,
        textarea {
          background: black;
          color:$white;
          font-family: $font-2;
          font-size: 1.2rem;
          border-bottom: 1px solid $color-2;
  
          @media screen and (min-width: 1200px){
            font-size: 1.5rem;
          }
        }
        input {
          padding: 0.5rem 1rem;
          width: 100%;
  
          @media screen and (max-width: 540px){
            width: 100%;
            padding: 12px 0 6px;
          }
        }
        .email-content {
          width: 100%;
          border-bottom: 1px solid $color-2;
  
          #not-mail {
            display: none;
            position: absolute;
            font-family: $font-2;
            top: 8px;
            color: $color-1;
            right: 0;
            transform-origin: 50% 50%;
          
          }
          input {
            background: black;
            border: none;
          }
        }
        textarea {
          background: black;
          resize: none;
          padding: 24px 14px;
  
          @media screen and (max-width: 540px){
            width: 100%;
            padding: 12px 0;
          }
        }
      }
      .error::placeholder {
        color: rgb(253, 87, 87);
      }
      .form-message {
        font-size: x-large;
        margin-top: 10px;
        padding: 12px;
        opacity: 0;
        background: $color-2;
        transition: 0.3s ease;
        color: $color-1;
        border-radius: 4px;
        box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
      }
    }
    .contact-infos {
      width: 30%;
      @media screen and (max-width: 540px){
        width: 56%;
      }
      @media screen and (max-width: 1199px){
      height: 44vh;
    }
   
  
      h4 {
        //color: $color-3;
        color: $white;
        font-size: 3rem;
        margin-bottom: 10px;
        //text-shadow: $shadow;
      
  
        @media screen and (min-width: 1200px){
          font-size: 5rem;
        }
      }
      p {
        font-family: $font-2;
        color: $white;
        @media screen and (min-width: 1200px){
          font-size: 1.5rem;
        }
      }
      .email .content p,
      .phone .content p,
      .adress .content p{  
        &:hover {
          transform: translateX(5px);
        }
      }
      .address {
        position: relative;
        .content {
          right: 10px;
        }
      }
      .phone {
        position: relative;
  
        @media screen and (max-width: 540px){
          text-align: center;
          font-size: .8rem;
        }
  
        .content {
          left: 40%;
          transform: translateX(-50%);
        }
      }
      .email {
        @media screen and (max-width: 540px){
          font-size: .8rem;
          text-align: center;
        }
      }
    }
//adress,tel etc
    .contact{
        direction: rtl;
        // display: flex;
        justify-content: center;
        width: 100%;
        // @media screen and (max-width: 1199px)
        // {
        //  height: 24rem;
        // }
        @media screen and (max-width: 883px)
        {
         width: 90%;
         text-align: center;
        }
    }
    .img-contact {
      z-index: -1;
      position: absolute;
      top:53rem;
      img{
        border-radius: $border-radius;
        width: 80%;
        max-width: 80%;
        @media screen and (max-width: 1199px)
      {
       width: 50%;
      }
      @media screen and (max-width: 883px)
      {
       display:none;
      }
      }
  }
//réseaux sociaux
.social-network{
    margin: 6rem 0;
}
.abonne{
    display: flex;
    justify-content: center;
    font-size: 4rem;
   // color: $color-2;
   color: $white;
    //text-shadow: $shadow;
    @media screen and (max-width: 550px)
      {
       font-size: 2.5rem;
      }
      @media screen and (max-width: 367px)
      {
       font-size: 2rem;
      }
}
.content-social {
    display: flex;
    justify-content: center;
    font-size: 6em;
}
//iframe + title
.ici{
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 4rem;
    padding-bottom: 4rem;
    padding-top: 4rem;
    @media screen and (max-width: 550px)
      {
       font-size: 2.5rem;
      }
      @media screen and (max-width: 367px)
      {
       font-size: 2rem;
      }
    //text-shadow: $shadow;
    h3{
      //color: $color-3;
      color: $white;
    }
}
.iframe{
    width: 100%;
    margin: 0px 0px 29px 0px;
    padding:2rem;
    @media screen and (max-width: 1199px){
      margin: 4.5rem 0rem 0rem 0rem;
    }
    @media screen and (max-width: 299px){
      margin: 5rem 0rem 0rem 0rem;
    }
}
//button envoyer
input.button {
    // background-color: black;
    background: linear-gradient(45deg, #292626 10%,#413a3a  80%);
    color: whitesmoke;
    border-radius: 30px;
    padding: 0.8rem 3rem;
    margin-left: 0;
    margin-top: 1rem;
    cursor: pointer;

    &:hover{
      transform: scale(1.1);
      background: $color-2;
      box-shadow: 0.5px;
    }
    &:active{
    transform: scale(1);
    }
}
