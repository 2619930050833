.on-the-top{
  display: block;
  margin-top: -2.5rem;
}
.content-cards{
    display: grid;
    padding-top: 3rem;
    overflow: hidden;
    justify-content: center;
    //direction: ltr;
    
    // background:url("./../../img/chalants.jpg")no-repeat;
    // background-size: 100%;
    // background-size: 100% 100%;
    // padding:5rem 0rem 5rem 0rem;
    // border-radius: 0  0 207px 207px;
    // background-attachment: fixed;
    // background-position: right;
    // text-shadow: $shadow;
    // opacity: 0.8;
    // @media screen and (max-width: 700px){
    //     padding-top: 17rem;
    //   }
}
     
.card{
    padding-bottom: 5rem;
    // width: 28rem;
        margin-bottom: 2rem;
     height: 22rem;
     perspective: 1000px;
     width: 27rem;
     display: inline-block;
     justify-content: space-around;
     margin-left: 32em;
      @media screen and (max-width: 1000px){
        margin-left: 20em;
      }
      @media screen and (max-width: 740px){
        margin-left: 18em;
      }
      @media screen and (max-width: 700px){
        margin-left: 16em;
      }
      @media screen and (max-width: 670px){
        margin-left: 10em;
      }
      @media screen and (max-width: 600px){
        margin-left: 0;
      }
      @media screen and (max-width: 431px){
        width: 25rem;
      }
    //   @media screen and (max-width: 401px){
    //     width: 16rem;
    //   }
 }
     .card__inner{
         //width: 28%;
         height: 100%;
         transition: transform 1s;
         transform-style: preserve-3d;
         cursor: pointer;
         position: relative;
       
 }
         .is-flipped{
             transform: rotateY(180deg);
 
         }
 
         .card__face{
             position: absolute;
             width: 100%;
             height: 100%;
             -webkit-backface-visibility: hidden;
             backface-visibility: hidden;
             //overflow: hidden;
             border-radius: 16px;
            // box-shadow: 0px 3px 18px 3px rgba(0 ,0 ,0, 0.2);
         }
             .card__face--front{
                 //background-image: linear-gradient(to bottom right, $color-3, $color-2);
                 display: grid;
                 align-items: center;
                 justify-content: center;
                 .title-huitre-lbdr{
                  color: $color-1;
                  font-size: 45px;
                  margin-top: 0.2em;
                  text-align: center;
                     @media screen and (max-width: 550px){
                      padding-top: 1rem;
                      font-size: 32px;
                     }
                 }
                 .title-huitre{
                  color: $color-1;
                  font-size: 55px;
                  text-align: center;
                     @media screen and (max-width: 550px){
                      padding-top: 1rem;
                      font-size: 38px;
                     }
                 }
                 
                 .fa{
                    font-size: 1,5rem;
                     align-items: center;
                     margin: 0rem 0rem 1rem 6rem;
                     color: $color-3;
                     &:hover {
                         transform: none;
                       }
                 }
                
                 .huitres_img{
                    display: flex;
                    width: 20%;
                    position: relative;
                    margin-left: 11rem;
                    padding: 1rem 0;
                    //  margin:  0 auto 30px;
                    //  border-radius: 50%;
                    //  background-color: $white;
                    //  border: 5px solid $white;
                    //  object-fit: cover;
                 }
             }
 
             .card__face--back{
                //  background-color: $color-1;
                 transform: rotateY(180deg);
                 .card__content{
                    //  width: 27rem;
                   //border-radius: 23px;
                    // background-color: $color-1;
                    // border: 2px solid $color-3;
                    box-shadow: $shadow-1;
                     height: 100%;
                     @media screen and (max-width: 431px){
                        width: 25rem;
                      }
                      @media screen and (max-width: 420px){
                        // width: 23rem;
                        box-shadow: 0px 0.5px $white;
                      }
                      @media screen and (max-width: 496px){
                        background: none !important;
                        border: none;
                      }
                 
 
                     .card__header{
                         position: relative;
                         padding: 30px 30px 40px;
 
                         &::after{
                             content: '';
                             display: block;
                             position: absolute;
                             top: 0;
                             left: 0;
                             right: 0;
                             bottom: 0;
                             z-index: -1;
                             border-radius: 0 0 50%;
                             background-image: linear-gradient(to bottom left, $color-2 10%, $color-3, 115%);
                         }
                         
                        //  .sous_title{
                        //      color: $white;
                        //      font-size: 32px;
                        //      font-weight: 900;
                        //      text-transform:uppercase;
                        //      text-align:center;
                        //  }
                     }
                     .card__body{
                        //  padding: 30px;
                         @media screen and (max-width: 500px){
                            width: 26em;
                          }
                        //  @media screen and (max-width: 431px){
    
                        //     width: 23em;
                        //   }
                        //   @media screen and (max-width: 375px){
                        //     width: 17rem;
                        //   }
                        //   @media screen and (max-width: 360px){
                        //     width: 17rem;
                        //   }
                         .des_title{
                             //color: $black;
                             color: $white;
                             font-size:2rem;
                             font-weight: 600;
                             margin-bottom: 15px;
                             text-align: center;
                             margin-top: 30px;
                            //  @media screen and (max-width: 431px){
                            //     text-align: left;
                            //   }
                            @media screen and (max-width: 496px){
                                color: $white;
                                .button{
                                    margin-left: 9.1rem;
                                    margin-top: -2rem;
                                }
                              }
                         }
                         .des_huitres{
                             color: $white;
                             line-height: 1.5;
                             font-family: $font-2;
                             font-size: 20px;
                             text-align: center;
                             padding: 0 2rem;
                            //  @media screen and (max-width: 431px){
                            //    font-size: 18px;
                            //   }
                            @media screen and (max-width: 496px){
                                color: $white;
                              }
                              @media screen and (max-width: 360px){
                                font-size: 16px;
                                padding: 0 4rem;
                               }
                         }
                        //  .fa{
                        //      font-size: 1,5rem;
                        //      align-items: center;
                        //      margin: 0rem 0rem 0rem 5.2rem;
                        //      color: $color-3;
                        //      &:hover{
                        //          color: $white;
                        //      }
                        //  }
                     }
                 }
             }
           
//button
.button {
    // background-color: black;
    display: flex;
    background: linear-gradient(45deg, #292626 10%,#413a3a  80%);
    color: whitesmoke;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    // margin-top: 2.1rem;
    margin-top: -1rem;
    margin-left: 9.7rem;
    cursor: pointer;
    text-align: center;

    &:hover{
      transform: scale(1.1);
      background: $color-2;
      box-shadow: -1px 0px 3px 1px;
    }
    &:active{
    transform: scale(1);
    }
    @media screen and (max-width: 496px){
       text-align: center;
       margin-top: -0.9rem;
    }
        
    @media screen and (max-width: 375px){
        text-align: center;
      }
} 

//img des labels
.h_img{
    display: block;
    width: 15rem;
    height: 15rem;
    margin:  0 auto 0px;
    border-radius: 50%;
    background-color: $white;
    border: 5px solid $color-3;
    object-fit: cover;  
    @media screen and (max-width: 500px){
      width: 14rem;
    height: 14rem;
    }
}
.img-label {
  width: 14%;
    position: absolute;
    margin-left: 11.5rem;
    margin-top: -2rem;
}
//div des labels MO
.labelise{
  display: flex;
}
 